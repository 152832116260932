import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SERVER_URL } from 'src/environments/environment';
import { DATE } from '../constant';


@Injectable({
    providedIn: 'root'
})
export class AuthService {


    public signedUser: any;
    public accessToken: any;
    public userId: any;
    public clientId: any;
    public employeeId: any;
    public firstName: any;
    public lastName: any;
    public APIKey: any;
    public userRole: any; 
    public refreshToken: any;
    public signature: any;
    public userLocationID: any = "";
 
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    constructor(
        private http: HttpClient,
        public storage: Storage
    ) {
      
    }

    login(email: any, password: any) : Observable<any> {
        const url = SERVER_URL + '/api/register/log_in';
        console.log(email);
        let httpParams = new HttpParams()
        
        .set("Username", email)
        .set("Password", password);
        return this.http.post(url, httpParams , this.httpOptions);
    }



    logout(){
        
        this.storage.remove('auth');
        this.storage.clear();
        this.signedUser = ''
        this.accessToken = '';
        this.refreshToken = '';
    }

}
